import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseTemplateDialogDirective} from '@shared/base-dialog';
import {Address, AddressForm} from '@shared/address';
import {Order} from '@shared/order';
import {User} from '@shared/user';
import {some} from 'lodash';
import {combineLatest, Observable} from 'rxjs';
import {AddressPickerFacade} from './address-picker.facade';
import {Router} from "@angular/router";

@Component({
  selector: 'address-picker',
  templateUrl: 'address-picker.html',
  styleUrls: ['address-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressPickerComponent extends BaseTemplateDialogDirective implements OnInit {
  public static width = '512px';

  @Input() saveButtonName: string;

  @Input()
  public set order(order: Order) {
    this._order = order;
    this.resetComponent();
  }

  public get order(): Order | undefined {
    return this._order;
  }

  @Input()
  public set user(user: User) {
    this._user = user;
    if (this.addresses !== user.addresses) {
      this.addresses = user.addresses;
      this.resetComponent();
    }
  }

  public get user(): User {
    return this._user;
  }

  @Output() orderAddressChanged: EventEmitter<Address>;

  public isLoading$: Observable<boolean>;
  public selectedAddress: Address | null;
  public isAdding: boolean;
  public isEditing: boolean;
  public addresses: Array<Address>;

  public get isAddressChanged(): boolean {
    return this.order && this.order.pickupAddress?.id !== this.selectedAddress?.id
      || !this.order && this.user.activeAddress?.id !== this.selectedAddress?.id;
  }

  public get isUpserting(): boolean {
    return this.isEditing || this.isAdding;
  }

  private _user: User;
  private _order?: Order;

  constructor(private facade: AddressPickerFacade,public router:Router) {
    super();
    this.isLoading$ = combineLatest([
      this.facade.isSelecting$,
      this.facade.isUpserting$
    ], (...loadings) => some(loadings));
    this.orderAddressChanged = new EventEmitter();
  }

  public ngOnInit(): void {
    this.facade.init({
      onOrderAddressUpdate: (address) => {
        if (this.order?.pickupAddress?.id === address.id) {
          this.orderAddressChanged.emit(address);
        }
      },
      onOrderAddressCreate: (address) => this.orderAddressChanged.emit(address),
      onOrderAddressSelect: (address) => this.orderAddressChanged.emit(address)
    });
  }

  public saveButtonClicked(): void {
    if (this.selectedAddress) {
      if (this.isAddressChanged) {
        this.facade.select({
          address: this.selectedAddress,
          dialogID: this.dialogRef.id,
          isSelectingDefault: !this.order,
          withConfirmation: this.order && !this.order.laundryService?.hasZipCode(this.selectedAddress.zipCode)
        });
      } else {
        this.close();
      }
    }
  }

  public addressSelected(address: Address): void {
    this.isAdding = false;
    this.isEditing = false;
    this.selectedAddress = address;
    this.isServiceNew=false
  }

  public addAddressOptionClicked(): void {
    this.isAdding = true;
    this.isEditing = false;
    this.selectedAddress = null;
  }

  public editClicked(address: Address): void {
    this.selectedAddress = address;
    this.isEditing = true;
    this.isAdding = false;
  }

  public deleteClicked(address: Address): void {
    let selectData = {
      address: this.getFirstZip(),
      isSelectingDefault: !this.order,
      withConfirmation: this.order && !this.order.laundryService?.hasZipCode(this.selectedAddress.zipCode)
    }
    this.facade.delete(address, selectData,this.dialogRef.id);
  }

  getFirstZip() {
    const result = this.addresses.filter(s => s.address == null);
    return result?.[0]
  }

  public formCancelClicked(): void {
    this.resetComponent();
  }

  public formSubmitted(formValue: AddressForm): void {

    if (this.hasNotSameAddress(formValue)) {
      this.facade.select({
        address: this.hasNotSameAddress(formValue),
        dialogID: this.dialogRef.id,
        isSelectingDefault: true,
        withConfirmation: false
      });
    } else {
      this.facade.upsert({
        formValue,
        address: this.isEditing && this.selectedAddress,
        withConfirmation: !!this.order
          && (!this.isEditing || this.selectedAddress.id === this.order.pickupAddress.id)
          && !this.order.laundryService?.hasZipCode(formValue.zipCode),
        dialogID: this.dialogRef.id ? this.dialogRef.id : null,
      });
    }

  }

  private hasNotSameAddress(formValue) {
    return this.addresses.find((address) =>
      (this.ifNull(address.address) === formValue.address) &&
      (this.ifNull(address.apt) === formValue.apt) &&
      (this.ifNull(address.buildingName) === formValue.buildingName) &&
      (this.ifNull(address.gatecode) === formValue.gatecode))
  }

  ifNull(data) {
    if (data === null) {
      return "";
    }
    return data;
  }
  isServiceNew:boolean=false
  private resetComponent(): void {
    this.isEditing = false;

    if (this.order) {
      this.selectedAddress = this.user?.activeAddress && this.order.laundryService?.hasZipCode(this.user.activeAddress.zipCode) ? this.user?.activeAddress  : this.order.pickupAddress;
    } else {
      this.selectedAddress = this.user?.activeAddress;
    }
    this.isAdding = !this.user?.addresses.length;
    if(this.isOrderCreatition){
      this.selectedAddress=null
      this.isAdding=true
      this.isServiceNew=true;
    }
  }
 public get isOrderCreatition(){
    return this.router.url.includes('/service') && this.router.url!='/services'
  }
}
